<template>
  <div class="order">
    <div class="title">
      <p>Payment information</p>
    </div>
    <div class="form">
      <div class="T-form">
        <table width="100%"  v-loading="loading"
    element-loading-text="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
          <tr class="tr">
            <td>*company:</td>
            <td>
              <td><input type="text" v-model="company" /></td>
            </td>
          </tr>
          <tr class="tr">
            <td>*Po No:</td>
            <td><input type="text" v-model="po_no" /></td>
          </tr>
          <tr class="tr">
            <td>*Terms</td>
            <td>
              <select name="" id="" v-model="terms">
                <option value="1">App</option>
                <option value="2">Credit Card</option>
                <option value="3">N30</option>
                <option value="4">other</option>
              </select>
            </td>
          </tr>

          <tr class="tr">
            <td>*Rep:</td>
            <td><input type="text" v-model="rep" /></td>
          </tr>

          <tr class="tr">
            <td>*Ship Date:</td>
            <td><input type="text" v-model="ship_date" /></td>
          </tr>

          <tr class="tr">
            <td>*Ship Via:</td>
            <td><input type="text" v-model="ship_via" /></td>
          </tr>

          <tr class="tr">
            <td>Cancel Date:</td>
            <td><input type="text" v-model="cancel_date" /></td>
          </tr>

          <tr class="tr">
            <td>Note:</td>
            <td><textarea v-model="note"></textarea></td>
          </tr>
        </table>
      </div>
    </div>
    <!-- 
    <div class="info">
      <div class="info-title">Consignee information</div>
      <section>
        <p>Consignee:</p>
        <p>
          {{ $store.state.userinfo.first_name }}
          {{ $store.state.userinfo.last_name }}
        </p>
      </section>
      <section>
        <p>Phone number:</p>
        <p>{{ $store.state.userinfo.mobile }}</p>
      </section>
      <section>
        <p>Address:</p>
        <p>
          {{ $store.state.userinfo.address }}
        </p>
      </section>
    </div>

    <div class="order">
      <div class="info-title">Confirm Order Information</div>
      <div class="all d-flex">
        <section class="col-2"></section>
        <section class="col-6">commodity</section>
        <section class="col-1">price</section>
        <section class="col-2">quantity</section>
        <section class="col-1">total</section>
      </div>

      <div
        :class="item.check ? 'single-true' : ''"
        class="d-flex single"
        v-for="(item, index) of car"
      >
        <section class="single-check thumb col-2 d-flex">
          <img :src="item.thumb" alt="" />
        </section>
        <section class="col-6 d-flex">
          <p class="col-7">{{ item.description }}</p>
          <p class="col-5 item-title1">{{ item.item_name }}</p>
        </section>
        <section class="col-1">${{ item.price }}</section>
        <section class="col-2">x{{ item.num }}</section>
        <section class="col-1 count-totla">${{ item.total }}</section>
      </div>
    </div>

    <div class="submit">
      <section>
        <p>
          <span style="color: #ff7f00">{{ this.car.length }}</span> items,
          total:
        </p>
        <p>${{ count }}</p>
      </section>
      <section>
        <p>Amount payable:</p>
        <p style="color: #de0e12; font-size: 0.55rem">${{ count }}</p>
      </section>
      <section>
        <div class="submit-button" @click="submit">Confirm Order</div>
      </section>
    </div> -->

    <div class="submit-button">
      <span @click="submit">Confirm Order</span>
    </div>
  </div>
</template>
<script>
import { cartAddToOrders, getOrderslistByids } from "network/order";
import { Loading } from "element-ui";

export default {
  name: "Order",
  data() {
    return {
      // count: 0,
      // num: 0,
      // car: [],
      company: "",
      po_no: "",
      terms: "1",
      rep: "",
      ship_date: "",
      ship_via: "",
      cancel_date: "",
      note: "",
      rep: "",
    };
  },
  created() {
    this.company = this.$store.state.userinfo.shipping.company;
    getOrderslistByids(this.$route.query.arr).then((res) => {
      if (res.data.code == 1) {
        this.car = res.data.data;
        this.count = 0;
        for (let i = 0; i < res.data.data.length; i++) {
          this.count = (
            Number(this.count) + Number(res.data.data[i].total)
          ).toFixed(2);
        }
      } else {
        return;
      }
    });
  },

  methods: {
    submit() {
      const loading = this.$loading({
        lock: true, //lock的修改符--默认是false
        text: "Loading", //显示在加载图标下方的加载文案
        spinner: "el-icon-loading", //自定义加载图标类名
        background: "rgba(0, 0, 0, 0.7)", //遮罩层颜色
        target: document.querySelector("#table"), //loadin覆盖的dom元素节点
      });
      //成功回调函数停止加载

      if (
        this.company == "" ||
        this.po_no == "" ||
        this.ship_date == "" ||
        this.ship_via == "" ||
        this.rep == ""
      ) {
        loading.close();
        this.$notify({
          message: "Please complete this form",
          type: "error",
          duration: 3000,
          position: "top-left",
          showClose: false,
          offset: 300,
        });
      } else {
        cartAddToOrders(
          this.$route.query.arr,
          this.company,
          this.po_no,
          this.terms,
          this.rep,
          this.ship_date,
          this.ship_via,
          this.cancel_date,
          this.note
        ).then((res) => {
          loading.close();
          if (res.data.code == 1) {
            let id = res.data.data.id;
            this.$router.push({
              path: "/orderSucceed",
              query: {
                id,
              },
            });
          } else {
            this.$notify({
              message: res.data.msg,
              type: "error",
              duration: 3000,
              position: "top-left",
              showClose: false,
              offset: 300,
            });
          }
        });
      }
    },
  },
};
</script>
<style scoped>
p {
  margin: 0;
}
.order {
  border: 1px solid #ededed;
  box-shadow: 0.1rem 0.1rem 0.1rem #ededed;
  margin: 0.5rem 2.5%;
  border-bottom: 1px solid #ededed;
}
.title {
  display: flex;
  font-size: 0.6rem;
  color: #999999;
  align-items: center;
  border-bottom: 1px solid #ededed;
}
.title p {
  padding: 0.25rem 0;
  margin: 0 0.25rem;
}
/* .all {
  padding: 0.25rem;
  background: #f2f2f2;
  font-size: 0.35rem;
  color: #666666;
}
.all section {
  padding: 0;
  margin: 0;
}
.single section {
  padding: 0;
  margin: 0;
}
.single p {
  padding: 0;
  margin: 0;
}
.single {
  font-size: 0.35rem;
  border-bottom: 1px solid #ededed;
  padding: 0.25rem;
}
.single-true {
  background: #ffecd9;
}
.single-check {
  align-items: center;
}
.thumb {
  width: 100%;
  height: 4rem;
}
.single-check img {
  width: 80%;
  height: 100%;
  object-fit: contain;
}
.quantity button:first-child {
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.25rem;
  border-radius: 0.1rem 0 0 0.1rem;
}
.quantity button:last-child {
  border: 1px solid #e0e0e0;
  padding: 0.1rem 0.25rem;
  border-radius: 0 0.1rem 0.1rem 0;
}
.quantity input {
  width: 1rem;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
}
.remove img {
  cursor: pointer;
}
.info {
  margin: 0 2.5%;
  border-bottom: 1px solid #ededed;
  padding-bottom: 0.5rem;
}
.info-title {
  padding: 0.3rem 0;
  font-size: 0.5rem;
  color: #ff7f00;
}
.info section {
  margin: 0 2.5%;
  display: flex;
  margin-bottom: 0.25rem;
}
.info section p {
  margin-right: 0.5rem;
  color: #666666;
  font-size: 0.35rem;
}

.item-title1 {
  color: #999999;
}
.count-totla {
  color: #de0e12;
}
.submit {
  padding: 0 2.5%;
}
.submit section {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #666666;
  font-size: 0.35rem;
  margin-top: 0.25rem;
}
.submit section p {
  margin-left: 0.5rem;
}
.submit-button {
  margin: 0.75rem 0 1.25rem 0;
  color: #fff;
  background: #ff7f00;
  padding: 0.15rem 0.25rem;
  border-radius: 0.1rem;
  cursor: pointer;
} */

.submit-button {
  text-align: center;
  margin: 0.5rem 0;
}

.submit-button span {
  padding: 0.25rem;
  font-size: 0.3rem;
  color: #fff;
  background: #ff7f00;
  cursor: pointer;
  border-radius: 0.1rem;
}

.form {
  font-size: 0.35rem;
  color: #737373;
}
table {
  margin-top: 0.5rem;
  width: 96%;
  margin: 0 auto;
}
.tr {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
}
.tr td:first-child {
  width: 30%;
  display: block;
  align-items: center;
  text-align: end;
}
.tr td:last-child {
  width: 68%;
  padding-left: 2%;
  display: block;
}
.tr td input {
  width: 80%;
  padding: 0.25rem;
  border: 1px solid #737373;
}
textarea {
  border: 1px solid #737373;
  outline: none;
  resize: none;
  padding: 0.25rem;
  width: 80%;
  min-height: 200px;
}

@media (max-width: 768px) {
  .tr {
    display: block;
  }
  .tr td:first-child {
    width: 100%;
    display: block;
    text-align: left;
  }
  .tr td:last-child {
    width: 100%;
    display: block;
    padding-left: 0;
  }
  .tr td input {
    width: 100%;
  }
  .font {
    display: block;
  }
  .font p {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }
  .font span {
    margin-left: 0;
  }
  textarea {
    width: 100%;
    min-height: 200px;
  }
}
</style>
